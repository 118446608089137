import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { get } from 'lodash';
import { FilterOption } from '../../modules/filters/models/filter-option.model';
import { AbstractControl, FormControl } from '@angular/forms';
import { ObservableComponent } from '../observable/observable.component';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent extends ObservableComponent implements OnInit {
  selectedOption: any;
  @Output() selected = new EventEmitter<any>();
  @Output() resetOption = new EventEmitter<any>();
  @Input() options: any[] | FilterOption<any>[];
  @Input() optionLabelPath: string;
  @Input() optionValuePath: string;
  @Input() placeholder: string;
  @Input() prefix: string;
  @Input() control: AbstractControl;
  @Input() search = false;
  @Input() multiLevel = false;
  @Input() firstLevelIndicator: string;
  @Input() hideAll = false;
  @Input() searchPlaceholder;
  searchControl = new FormControl('');
  @Output() searchChanged = new EventEmitter<string>();

  constructor(@Inject('applicationName') public applicationName: string) {
    super();
  }

  ngOnInit(): void {
    if (this.search) {
      this.initSearchControlListener();
    }

    this.control.valueChanges.pipe(
      filter(val => !!val),
      takeUntil(this.ngDestroy$),
      tap(() => {
        this.selectedOption = this.transformToOptionValue();
      })
    );

    if (!this.selectedOption && this.control.value) {
      this.selectedOption = this.transformToOptionValue();
    }
  }

  handleSelectOption(option): void {
    if (this.selectedOption !== option) {
      this.selectedOption = option;
      this.selected.emit(option);
    }
  }

  handleReset(): void {
    this.selectedOption = null;
    this.resetOption.emit();
  }

  optionLabel(option): string {
    if (!this.optionLabelPath) {
      return option;
    }

    const label = get(option, this.optionLabelPath);

    return label ?? option;
  }

  private initSearchControlListener() {
    this.searchControl.valueChanges.pipe(takeUntil(this.ngDestroy$), debounceTime(300)).subscribe(value => {
      this.searchChanged.emit(value!);
    });
  }

  private transformToOptionValue() {
    if (!this.optionValuePath) {
      return this.control.value;
    }

    const valueFromOptions = this.options.find(option => option[this.optionValuePath] === this.control.value);

    return valueFromOptions ? valueFromOptions : this.control.value;
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import packageInfo from '../../../../package.json';
import { KNOWLEDGE_SERIES_OPTIONS } from '../../../todaylib/static/knowledge-series/knowledge-series-constants';

export const environment = {
  version: packageInfo.version,
  production: false,
  apiBaseUrl: 'https://17715ivenb.execute-api.eu-central-1.amazonaws.com/prod/',
  s3BucketUrl: 'https://medtoday-stack-de-dev-adm-appassetsandcontentbucke-etaomozif38o.s3.eu-central-1.amazonaws.com/',
  reactAppScriptUrl: 'https://medtoday-react.de/api/embed-talks-app.js',
  surveyMonkeyScriptUrl:
    'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd0WVBhNgy6f6CQjMfORyTPdSROSQ45TB1p9jQbFBnAPF.js',
  name: 'MedToday',
  language: 'de',
  domain: 'de',
  payRexxDomain: 'medtoday-tests',
  cognito: {
    cognitoUserPoolId: 'eu-central-1_z4oVpiVz6',
    cognitoAppClientId: '66i9hk3vdrh3qeq1lsdh2tu66b',
    cognitoIdentityPoolId: 'eu-central-1:12a2e15d-2d99-4f87-8f9b-875921cd1d15',
    appInstanceArn: '',
    region: 'eu-central-1',
    oauth: {
      domain: 'medtoday-dev-de.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://dev.medtoday.de/',
      redirectSignOut: 'https://dev.medtoday.de/',
      responseType: 'code'
    }
  },
  twitterWallId: '3423',
  twitterProfileUrl: 'https://twitter.com/medtodaygermany',
  linkedInProfileUrl: 'https://www.linkedin.com/company/medtoday/',
  instagramProfileUrl: 'https://www.instagram.com/medtodaygermany/',
  medboardsUrl: 'http://localhost',
  medBoardsApiUrl: 'http://localhost/api',
  hasNewsletter: true,
  megaMenuFilterExclude: 'MedEd',
  youtubeApiKey: '',
  knowledgeSeriesVideosIds: {
    [KNOWLEDGE_SERIES_OPTIONS[0]]: [323],
    [KNOWLEDGE_SERIES_OPTIONS[1]]: [323, 324],
    [KNOWLEDGE_SERIES_OPTIONS[2]]: [325]
  }
};

export const imagePaths = {
  logos: 'logo/', // sponsors, patrons, website logos etc.
  thumbnails: 'thumbnail/', // video thumbnails, feature blocks
  avatars: 'avatar/', // profile pictures
  images: '', // general images
  pdfs: 'pdf/'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
